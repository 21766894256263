import * as R from 'ramda';
import moment from 'moment-timezone';
import { getSessionToken } from '../api/rest/utils';


const getPubNubGrandToken = async () => {
  const { API_URL } = window.MILO_CONFIG;

  const response = await fetch(`${API_URL}/chat/grantToken`, {
    method: 'POST',
    headers: {
      'session-token': getSessionToken(),
    },
  });

  const data = await response.json();

  return data;
};

const fetchChatList = async (profileId, page = 1, pageSize = 1) => {
  const { API_URL } = window.MILO_CONFIG;

  const response = await fetch(`${API_URL}/chat?profileId=${profileId}&page=${page}&pageSize=${pageSize}`, {
    method: 'GET',
    headers: {
      'session-token': getSessionToken(),
      'source': 'WEB'
    },
  });

  const data = await response.json();

  return data;
};

const fetchChatByProgram = async (epId) => {
  const { API_URL } = window.MILO_CONFIG;

  const response = await fetch(`${API_URL}/chat/${epId}`, {
    method: 'GET',
    headers: {
      'session-token': getSessionToken(),
      'source': 'WEB'
    },
  });

  const data = await response.json();

  return data;
};

const formattedOnlineMessage = (message, members) => {
  const curDay = moment(Number(message.timetoken.slice(0, message.timetoken.length - 4))).format('D MMM gggg');
  const SP = 'Some People';
  let sender = message.sender || {};
  if (!R.is(Object, sender)) {
    const uuid = sender;
    sender = {
      uuid,
      state: {
        nickName: SP,
      },
    };
  }

  if (!sender.uuid) {
    sender = {
      uuid: SP,
      state: {
        nickName: SP,
      },
    };
  } else {
    sender = R.pick(['uuid', 'state'], sender);
    const user = R.path([sender.uuid], members);
    
    if (!sender.state.nickName || sender.state.nickName === SP) {
      if (user) {
        sender.state = {
          nickName: `${user.name.firstName} ${user.name.lastName}`,
          avatar: user.profile_photo,
        };
      } else {
        sender.state = {
          nickName: SP,
        };
      }
    }

    if (sender.state.nickName && sender.state.nickName !== SP) {
      if (user) {
        sender.state = {
          nickName: `${user.name.firstName} ${user.name.lastName}`,
          avatar: user.profile_photo,
          status: 'online',
          type: user.type,
        };
      } else {
        sender.state = {
          nickName: SP,
        };
      }
    }
  }

  return {
    ...message.data,
    sender,
    timetoken: message.timetoken,
    curDay,
  };
};


export { getPubNubGrandToken, fetchChatList, fetchChatByProgram, formattedOnlineMessage };
