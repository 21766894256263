import React from 'react';
import {
  Grid,
  Dialog,
  Typography,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  Icon,
} from '@material-ui/core';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon, IndeterminateCheckBoxIcon } from 'utilsModule/material-ui-icons';
import Fab from '@material-ui/core/Fab';
import logo from 'dataModule/assets/img/HealthBeatsLogo.svg';
import { withStyles } from '@material-ui/styles';
import commonUtil from 'utilsModule/common';

// Helper function to compare two objects by their JSON string representations
const areFiltersEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

// Helper function to determine if an object is empty
const isEmptyObject = (obj) => obj !== null && Object.keys(obj).length === 0;

const styles = () => ({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    height: '69px',
    width: '552px',
    alignItems: 'center',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  body: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  footer: {
    height: '69px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  paper: {
    borderRadius: '8px',
    width: '654px',
    height: '600px',
  },
  chargeCodePaperStyle: {
    borderRadius: '8px',
    width: '750px',
    maxWidth: '750px',
    height: '550px',
  },
  headerTitle: {
    color: '#212121',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.19,
    fontStretch: 'normal',
    letterSpacing: '0.5px',
    paddingTop: '9px',
    paddingLeft: '10px',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.15px',
    color: '#007AFF',
  },
  descriptions: {
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.26px',
    color: '#212121',
    opacity: 1,
  },
  close: {
    height: '10px',
    width: '38px',
    float: 'right',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  action: {
    justifyContent: 'space-between',
    marginBottom: '10px',
    marginTop: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
    borderTop: '1px solid #F0F0F0',
  },
});

@withStyles(styles, { index: 1 })
class ListFilterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listFilterOptions: [],
      filter: {},
      defaultFilter: {},
    };
  }

  componentDidMount() {
    const { listName, filter, defaultFilter, location: { query: { page: pageValue = '1' } = {} } = {} } = this.props;
    const page = commonUtil.validateQuery(pageValue, 'PAGE') ? pageValue : '1';
    let listFilterOptions = [];
    
    switch (listName) {
      case 'PARTICIPANT':
        listFilterOptions = commonUtil.participantListFilterOptions;
        break;
      case 'ALERT':
        listFilterOptions = commonUtil.alertListFilterOptions;
        break;
      case 'ALERT_SUMMARY':
        listFilterOptions = commonUtil.alertSummaryListFilterOptions;
        break;
      case 'PAUSED_SUMMARY':
        listFilterOptions = commonUtil.pausedSummaryListFilterOptions;
        break;
      case 'CHARGE_ITEM_CODE':
        listFilterOptions = commonUtil.chargeItemCodeFilterOptions;
        break;
    }

    if (filter == null) {
      // Set selected all
      listFilterOptions = listFilterOptions.map((m) => ({
        ...m,
        options: m.options.map((opts) => ({
          ...opts,
          checked: true,
        })),
      }));
    } else {
      // Set value
      listFilterOptions = listFilterOptions.map((m) => {
        const options = m.options.map((opts) => {
          let checked = false;
          if (filter[m.columnName]) checked = filter[m.columnName].includes(opts.value);
          return { ...opts, checked };
        });

        // handle if selected all
        const getUnselected = options.filter((f) => !f.checked);
        if (getUnselected.length === 1) options[0].checked = true;
        return { ...m, options };
      });
    }

    this.setState({ listFilterOptions, defaultFilter }, () => this.handleApplyFilter(parseInt(page) - 1));
  }

  handleChecked = async (index, optionIndex) => {
    const { listFilterOptions } = this.state;
    const checked = !listFilterOptions[index].options[optionIndex].checked;
    listFilterOptions[index].options[optionIndex].checked = checked;
    if (optionIndex === 0) {
      // Select all
      if (checked) {
        // Set child to checked
        listFilterOptions[index].options = await listFilterOptions[index].options.map((item) => ({
          ...item,
          checked: true,
        }));
      } else {
        // Set child to unchecked
        listFilterOptions[index].options = await listFilterOptions[index].options.map((item) => ({
          ...item,
          checked: false,
        }));
      }
    } else {
      const haveUnchecked = await listFilterOptions[index].options.find((f, i) => i !== 0 && !f.checked);
      listFilterOptions[index].options[0].checked = haveUnchecked ? false : true;
    }

    const filter = {};
    for (const item of listFilterOptions) {
      if (item.options.find((f) => f.checked)) {
        filter[item.columnName] = await item.options.filter((f, i) => i !== 0 && f.checked).map((m) => m.value);
      }
    }

    this.setState({ listFilterOptions, filter });
  };

  handleApplyFilter = async (page = 0) => {
    const { handleChangeFilter } = this.props;
    const { listFilterOptions } = this.state;
    let filter = null;
    let count = 0;
    for (const item of listFilterOptions) {
      if (item.options.find((f) => f.checked)) {
        if (!filter) filter = {};
        filter[item.columnName] = await item.options.filter((f, i) => i !== 0 && f.checked).map((m) => m.value);
      }

      // Count
      if (item.options.find((f) => !f.checked)) {
        count++;
      }
    }
    this.setState({ filter });
    handleChangeFilter(filter, count, page);
  };

  handleReset = () => {
    const { listFilterOptions: data } = this.state;
    const listFilterOptions = data.map((m) => ({
      ...m,
      options: m.options.map((opts) => ({
        ...opts,
        checked: true,
      })),
    }));

    const filter = {};
    for (const item of listFilterOptions) {
      if (item.options.find((f) => f.checked)) {
        filter[item.columnName] = item.options.filter((f, i) => i !== 0 && f.checked).map((m) => m.value);
      }
    }

    this.setState({ listFilterOptions, filter });
  };

  renderDescription(listName) {
    const { descriptions } = this.props;

    if (listName === 'ALERT_SUMMARY') {
      return (
        <Grid style={{ paddingBottom: '24px', paddingTop: '16px' }}>
          <Typography className={descriptions} style={{ paddingTop: '15px' }}>
            You can select and configure the display of your dashboard.
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid style={{ paddingBottom: '24px', paddingTop: '16px' }}>
        <Typography className={descriptions}>
          You can select various filters to help you manage what you see in the table. If you select multiple filters,
          they will apply concurrently. At least 1 selection is required for each category.
        </Typography>
        <Typography className={descriptions} style={{ paddingTop: '15px' }}>
          All filters you selected and applied will be saved for all future logins. You can reset your filter settings
          to system default by using the ‘Reset’ button.
        </Typography>
      </Grid>
    );
  }

  render() {
    const {
      listName,
      openDialog,
      handleCloseDialog,
      classes: { paper: paperCommon, chargeCodePaperStyle, headerTitle, descriptions, close, action, title },
      filter: propsFilter,
    } = this.props;
    const { listFilterOptions, filter, defaultFilter } = this.state;

    // Set default value for propsFilter if it's falsy
    const defaultPropsFilter = propsFilter || {};

    // Prepare the effective filter for comparison
    const effectivePropsFilter =
      listName === 'ALERT'
        ? (({ measurementType, ...rest }) => rest)(defaultPropsFilter) // remove measurementType property
        : defaultPropsFilter;

    // Determine if the button should be disabled
    const disableButton = areFiltersEqual(filter, effectivePropsFilter) || (filter !== null && isEmptyObject(filter));

    const disableResetButton = JSON.stringify(defaultFilter) === JSON.stringify(filter ? filter : {});
    const paper = listName === 'CHARGE_ITEM_CODE' ? chargeCodePaperStyle : paperCommon;
    return (
      <Dialog
        classes={{ paper }}
        open={openDialog}
        onClose={() => {
          handleCloseDialog();
        }}
      >
        <DialogTitle id="ihealth-customer-care-info" style={{ borderBottom: '1px solid #F0F0F0' }}>
          <Grid container direction="row">
            <Grid item xs={6} style={{ display: 'flex' }}>
              <img src={logo} />
              <Typography variant="h6" className={headerTitle}>
                Filter Settings
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Fab onClick={() => handleCloseDialog()} aria-label="Add" className={close}>
                <Icon fontSize="inherit" style={{ color: '#4c4b4b', fontSize: '20px' }}>
                  close
                </Icon>
              </Fab>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid>
            {this.renderDescription(listName)}
            {listFilterOptions.map((item, index) => {
              return (
                <Grid key={index}>
                  <Grid style={{ paddingBottom: '24px', paddingTop: index > 0 ? '24px' : null }}>
                    <Typography className={title}>{item.columnTitle}</Typography>
                  </Grid>
                  <Grid container direction={'row'}>
                    {item.options.map((child, childIndex) => {
                      return (
                        <Grid key={childIndex} item xs={4} style={{ marginBottom: '20px' }}>
                          <Grid container direction="row">
                            {childIndex === 0 ? (
                              <Checkbox
                                icon={
                                  item.options.find((f, i) => i !== 0 && f.checked) ? (
                                    <IndeterminateCheckBoxIcon style={{ color: '#3FD762' }} />
                                  ) : (
                                    <CheckBoxOutlineBlankIcon />
                                  )
                                }
                                checkedIcon={<CheckBoxIcon />}
                                onChange={() => this.handleChecked(index, childIndex)}
                                checked={child.checked}
                              />
                            ) : (
                              <Checkbox
                                onChange={() => this.handleChecked(index, childIndex)}
                                checked={child.checked}
                              />
                            )}
                            <Typography
                              variant="body1"
                              style={{
                                paddingTop: '12px',
                                fontSize: '16px',
                                color: child.checked ? 'black' : '#9e9e9e',
                              }}
                            >
                              {child.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {index < listFilterOptions.length - 1 && (
                    <Grid style={{ paddingTop: '24px' }}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions className={action}>
          <Button
            style={{ width: '180px' }}
            variant="contained"
            className="milo-btn-white"
            onClick={() => this.handleReset()}
            disabled={disableResetButton}
          >
            Reset
          </Button>
          <Button
            className="milo-btn-orange"
            style={{ width: '180px' }}
            variant="contained"
            onClick={() => this.handleApplyFilter()}
            disabled={disableButton}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ListFilterDialog;
